import { List, Stack } from '@mui/material';
import { IFilterCategoryProperties } from 'src/apis/types/filterListAPI';
import { Typography } from 'src/components/mui-components/Typography';
import { InputButton } from '../InputButton';
import styles from './InputGroup.module.scss';

export const InputGroup = ({ filterCategory, filterItems }: IFilterCategoryProperties) => (
  <Stack key={filterCategory} className={styles.groupList}>
    <div className={styles.groupTitle}>
      <Typography
        key={filterCategory}
        component="h3"
        variant="body1"
        fontWeight={600}
        data-automation-id={`GroupHeading${filterCategory}`}
      >
        {filterCategory}
      </Typography>
    </div>
    <List>
      {filterItems.map(({ id, ...filterItem }) => (
        <InputButton key={id} id={id} {...filterItem} />
      ))}
    </List>
  </Stack>
);
