/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { useGetFilterAPI } from 'src/apis/filterAPI';
import { FilterPanelLayout } from 'src/components/layout/FilterPanelLayout';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import { IFilterProps, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import { safeParseJson } from 'src/utils/object';
import { ISavedView, useGetSavedView } from 'src/apis/savedViewAPI';

import { useGetCurrentPageIdentifier } from 'src/stores/PageStore';
import { FilterSection } from 'src/components/layout/FilterPanelLayout/components/MainContainer/components/FilterSection';
import isEmpty from 'lodash/isEmpty';
import {
  SPSelectedFilterListStateKey,
  SPViewOptionsStateKey,
  SPFilterIsOpen,
} from './localStorageKeys';

import { IOptionsChangeParameters } from './types/searchProjects';
import { Table } from './components/Table';

export const SearchProjects = () => {
  localStorage.setItem(SPFilterIsOpen, 'true');

  const pageIdentifier = useGetCurrentPageIdentifier();
  const { filterList, isError, isLoading } = useGetFilterAPI(pageIdentifier);

  const { savedView } = useGetSavedView('search-projects');

  const [activeView, setActiveView] = useState<string>(savedView[0]?.id || '');

  const [changedViewOptions, setChangedViewOptions] = useState<any | undefined>(undefined);

  useEffect(() => {
    if (savedView && savedView.length > 0) {
      // const newActiveView = savedView[0]?.id || '';
      const newSavedViewOptions =
        savedView.find((v: any) => v.id === activeView)?.viewOptions ?? undefined;

      if (JSON.stringify(newSavedViewOptions) !== JSON.stringify(changedViewOptions)) {
        setChangedViewOptions(changedViewOptions);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedView]);

  useEffect(() => {
    const newSavedViewOptions =
      savedView.find((v: any) => v.id === activeView)?.viewOptions ?? undefined;

    setChangedViewOptions(newSavedViewOptions);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeView]);

  // useEffect(() => {
  //   if (!savedViewOptions) {
  //     return;
  //   }
  //   setChangedViewOptions(savedViewOptions);
  // }, [savedViewOptions]);

  useEffect(() => {
    if (!changedViewOptions) {
      return;
    }
    localStorage.setItem(SPViewOptionsStateKey, JSON.stringify(changedViewOptions));
  }, [changedViewOptions]);

  const onViewOptionsChange = (optionItems: IOptionsChangeParameters[]) => {
    const options = optionItems.map((item) => ({ [item.name]: item.value }));
    const optionsToObject = Object.assign({}, ...options);

    setChangedViewOptions({
      ...changedViewOptions,
      ...optionsToObject,
    });
  };

  const getSelectedFilterFromLocalStorage = (): Record<TFilterID, IFilterProps> => {
    const filterFromLocalStorage: Record<TFilterID, IFilterProps> =
      safeParseJson(localStorage.getItem(SPSelectedFilterListStateKey) || '') || {};
    if (filterList.length > 0) {
      const flatMapped = filterList.flatMap((x) => x.filterItems).map((x) => x?.name);
      Object.keys(filterFromLocalStorage).forEach((key) => {
        if (flatMapped.indexOf(key) === -1) {
          delete filterFromLocalStorage[key];
        }
      });
    }
    return filterFromLocalStorage;
  };

  return (
    <ResponseHandler isLoading={isLoading} isError={isError} isEmpty={filterList.length <= 0}>
      <FilterPanelLayout
        filterList={filterList}
        selectedFilterList={getSelectedFilterFromLocalStorage()}
        savedViewsList={savedView}
        changedViewOptions={changedViewOptions}
        activeView={activeView}
        setActiveView={setActiveView}
      >
        <Table
          selectedViewOptions={changedViewOptions}
          onViewOptionsChange={onViewOptionsChange}
          filterSection={
            <FilterSection
              changedViewOptions={changedViewOptions}
              onViewOptionsChange={onViewOptionsChange}
            />
          }
        />
      </FilterPanelLayout>
    </ResponseHandler>
  );
};
