import { useQuery } from '@tanstack/react-query';
import { getFetch } from 'src/utils/fetcher';
import { getSavedViewResult } from 'src/mocks/projects/savedView';
import { toCamel } from 'src/utils/object';

const GET_SAVED_VIEW_URL_PATH = '/api/v2/saved-view'; // Change to correct name
// save-view/search-projects
// save-view/resource-planner

export const GET_SAVED_VIEW_MSW_STATUS_KEY = 'getSavedViewAPI';
export const GET_SAVED_VIEW_KEY = 'GET_SAVED_VIEW';

const getSavedView = (identifier: string): Promise<any> =>
  getFetch({
    path: `${GET_SAVED_VIEW_URL_PATH}/${identifier}`,
    key: GET_SAVED_VIEW_MSW_STATUS_KEY,
  });

export const useGetSavedView = (identifier: string) => {
  const { data, isError, ...rest } = useQuery(
    [GET_SAVED_VIEW_KEY, identifier],
    () => getSavedView(identifier),
    {
      retry: 0,
    },
  );

  if (isError) {
    // For testing, to be remove
    const { entities } = toCamel(getSavedViewResult) as any;
    return {
      savedView: entities ? entities.map(({ properties }: any) => ({ ...properties })) : [],
      isError: false,
      ...rest,
    };
  }

  const savedView = data?.entities
    ? data.entities.map(({ properties }: any) => ({ ...properties }))
    : [];

  return {
    savedView,
    ...rest,
  };
};
