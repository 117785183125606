import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Stack, Tab, Tabs } from '@mui/material';
import { z } from 'zod';
import { ISavedViewForm, SnackbarMode } from 'src/apis/savedViewAPI';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import { ToastifyAlert } from 'src/components/mui-components';
import { toast } from 'react-toastify';
import { TSnackbarKeys } from 'src/screens/SearchProjects/types/searchProjects';
import { SNACKBAR_CONFIG } from 'src/screens/SearchProjects/constants';
import { SaveViewDialog } from './components/SaveViewDialog';
import { EditViewDialog } from './components/EditViewDialog';

interface IFilterList {
  savedView: any;
  tabOnChange: any;
  activeView: any;
  hasChanges: any;
}

export const SaveViewContainer = ({
  savedView,
  tabOnChange,
  activeView,
  hasChanges,
}: IFilterList) => {
  const { t: tSavedView } = useTranslation('savedView');

  // region Save View Form
  const formSchema = z.object({
    title: z.string().min(1, { message: 'Please enter title' }),
    isDefault: z.boolean(),
  });

  const form = useForm({
    defaultValues: {
      title: '',
      isDefault: false,
    } as ISavedViewForm,
    resolver: zodResolver(formSchema),
  });

  const displayToast = (type: SnackbarMode) => {
    const config = SNACKBAR_CONFIG.find((c) => c.type === type);
    const { title, description } = config ?? {};
    toast.success(
      <ToastifyAlert
        title={tSavedView(title as TSnackbarKeys)}
        description={tSavedView(description as TSnackbarKeys)}
      />,
      {
        autoClose: 5000,
        closeButton: false,
      },
    );
  };

  const [saveViewDialog, setSaveViewDialog] = useState(false);
  const [editViewDialog, setEditViewDialog] = useState(false);

  const selectedSaveViewData = savedView.find((v: any) => v.id === activeView);

  const onSaveSubmit = (actionType: string, data?: ISavedViewForm) => {
    // eslint-disable-next-line no-console
    console.log('on onSubmit function', actionType, data);

    if (actionType === 'save') {
      // savedView
      displayToast('save');
    } else if (actionType === 'edit') {
      // savedView
      displayToast('edit');
    }
    // set default to false for all views
    // const viewData: ISavedView[] = savedViews.map((view) => ({
    //   ...view,
    //   isDefault: data.isDefault ? false : view.isDefault,
    // }));

    // const filters = localStorage.getItem(RPSelectedFilterListStateKey);
    // const newID = uuidv4();
    // let updatedData: ISavedView[];

    // switch (showFormDialog) {
    //   case 'save':
    //     if (!filters) {
    //       return;
    //     }

    //     updatedData = [
    //       ...viewData,
    //       {
    //         id: newID,
    //         title: data.title,
    //         period: data.period,
    //         isDefault: data.isDefault,
    //         createdDate: new Date(),
    //         updatedDate: new Date(),
    //         filters: safeParseJson(filters),
    //         viewOptions: selectedViewOptionsWithoutPeriod,
    //       },
    //     ];
    //     displayToast('save');
    //     updateActiveView(newID);
    //     break;

    //   case 'edit':
    //     updatedData = viewData.map((view) => {
    //       if (view.id === activeView) {
    //         return {
    //           ...view,
    //           title: data.title,
    //           period: data.period,
    //           isDefault: data.isDefault,
    //           updatedDate: new Date(),
    //         };
    //       }
    //       return view;
    //     });
    //     displayToast('edit');
    //     break;

    //   default:
    //     updatedData = [
    //       ...viewData,
    //       {
    //         id: newID,
    //         title: data.title,
    //         period: data.period,
    //         isDefault: data.isDefault,
    //         createdDate: new Date(),
    //         updatedDate: new Date(),
    //         filters: filters ? safeParseJson(filters) : {},
    //         viewOptions: selectedViewOptionsWithoutPeriod,
    //       },
    //     ];
    //     displayToast('duplicate');
    //     updateActiveView(newID);
    //     break;
    // }

    // updateSavedViews(updatedData);
    form.reset();
    setSaveViewDialog(false);
  };

  const onEditSubmit = (actionType: string) => {
    if (actionType === 'edit') {
      displayToast('edit');
    } else {
      displayToast('delete');
    }

    form.reset();
    setEditViewDialog(false);
  };

  const handleViewAction = (action: string) => {
    form.setValue('title', selectedSaveViewData?.title, {
      shouldDirty: true,
      shouldValidate: true,
    });
    form.setValue('isDefault', selectedSaveViewData?.isDefault);
    if (action === 'save') {
      setSaveViewDialog(true);
    } else {
      setEditViewDialog(true);
    }
  };

  return (
    <>
      <Stack direction="row" justifyContent="space-between">
        <Tabs
          value={activeView}
          onChange={(_, v) => tabOnChange(v)}
          variant="scrollable"
          scrollButtons="auto"
          aria-label="basic tabs example"
        >
          {savedView.map((v: any) => (
            <Tab
              component="a"
              key={v.id}
              value={v.id}
              label={v.title}
              wrapped
              aria-current={activeView === v.id}
              data-automation-id={`FilterContainer${v.title.replace(/ /g, '')}Tab`}
            />
          ))}
        </Tabs>
        <Stack direction="row" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleViewAction('save')}
            disabled={!hasChanges}
            data-automation-id="FilterContainerSaveChanges"
            sx={{ height: 'fit-content' }}
          >
            {tSavedView('SaveViewButtonText')}
          </Button>
          <Button
            variant="outlined"
            size="small"
            onClick={() => handleViewAction('edit')}
            data-automation-id="FilterContainerSavedView"
            sx={{ height: 'fit-content' }}
          >
            {tSavedView('EditViewButtonText')}
          </Button>
        </Stack>
      </Stack>
      <SaveViewDialog
        showSaveDialog={saveViewDialog}
        onClose={() => setSaveViewDialog(false)}
        form={form}
        onSubmit={onSaveSubmit}
        selectedSaveViewData={selectedSaveViewData}
      />
      <EditViewDialog
        showEditDialog={editViewDialog}
        onClose={() => setEditViewDialog(false)}
        form={form}
        onSubmit={onEditSubmit}
        selectedSaveViewData={selectedSaveViewData}
      />
    </>
  );
};
