export default {
  Entities: [
    {
      Properties: {
        Id: '0',
        Title: 'My projects',
        IsDefault: true,
        CreatedDate: '2024-8-12T17:15:33.008Z',
        UpdatedDate: '2024-8-12T17:15:33.008Z',
        Filters: {
          ProjectManager: {
            Label: 'ProjectManager',
            Values: [
              {
                label: 'NJonas Gam Nielsen',
                value: '1175',
              },
            ],
            ContentUrl: 'api/v2/filters/project-manager/content',
            ChildFilters: ['Project'],
            ParentFilters: [],
            IsInclude: true,
            Type: 'MultiSelectTypeSearch',
          },
        },
        ViewOptions: {
          SearchText: '',
        },
      },
    },
    {
      Properties: {
        Id: '1',
        Title: 'Active',
        IsDefault: false,
        CreatedDate: '2024-8-12T17:15:33.008Z',
        UpdatedDate: '2024-8-12T17:15:33.008Z',
        Filters: {
          ProjectStatus: {
            Label: 'ProjectStatus',
            Values: [
              {
                Label: 'Active projects',
                Value: '-1',
              },
            ],
            ContentUrl: 'api/v2/filters/project-status/content',
            ChildFilters: ['Project'],
            ParentFilters: [],
            IsInclude: true,
            Type: 'MultiSelectTypeSearch',
          },
        },
        ViewOptions: {
          SearchText: '_TIM',
        },
      },
    },
    {
      Properties: {
        Id: '2',
        Title: 'All',
        IsDefault: false,
        CreatedDate: '2024-8-12T17:15:33.008Z',
        UpdatedDate: '2024-8-12T17:15:33.008Z',
        Filters: {},
        ViewOptions: {
          SearchText: '',
        },
      },
    },
  ],
  Links: [
    {
      Href: 'http://localhost/tlp/api/v2/saved-view',
      Rel: 'self',
    },
  ],
};
