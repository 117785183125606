import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { ISavedView } from 'src/apis/savedViewAPI';
import { Deck } from 'src/components/ui-components';
import { TViewOptions } from 'src/screens/ResourcePlanner/types/resourcePlanner';
import { useFilterDispatch, useFilterStore } from 'src/stores/FilterStore';
import { SaveViewContainer } from '../SaveViewContainer';

interface IMainContainer {
  children: ReactNode;
  allowSavedViews?: boolean;
  savedView: ISavedView[];
  changedViewOptions?: TViewOptions;
  activeView: any;
  setActiveView: any;
}

export const MainContainer = ({
  children,
  allowSavedViews,
  savedView,
  changedViewOptions,
  activeView,
  setActiveView,
}: IMainContainer) => {
  const dispatch = useFilterDispatch();

  const [haveFilterChanges, setHaveFilterChanges] = useState(false);
  const [haveOptionChanges, setHaveOptionChanges] = useState(false);

  const { filterQueryObj } = useFilterStore();

  const handleFilterChanges = useCallback(
    (id: string) => {
      const payload = savedView?.find((v: any) => v.id === id)?.filters;
      if (dispatch) {
        dispatch({ type: 'RESET_FILTER' });
        if (payload && Object.keys(payload).length) {
          dispatch({ type: 'ADD_OR_UPDATE_FILTER', payload });
        }
        dispatch({ type: 'DEACTIVATE_CONTAINER_ID' });
        dispatch({ type: 'DEACTIVATE_PANEL_ID' });
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  const hasChanges = useMemo(
    () => haveFilterChanges || haveOptionChanges,
    [haveFilterChanges, haveOptionChanges],
  );

  const tabOnChange = (id: string) => {
    setActiveView(id);
    handleFilterChanges(id);
  };

  useEffect(() => {
    if (!activeView) {
      setActiveView(savedView[0]?.id || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedView]);

  useEffect(() => {
    setHaveFilterChanges(false);
    setHaveOptionChanges(false);
    if (allowSavedViews) {
      handleFilterChanges(activeView);
    }
  }, [activeView, allowSavedViews, handleFilterChanges]);

  // To set filter on first load of saved view when there is filter
  useEffect(() => {
    const payload = savedView?.find((v: any) => v.id === activeView)?.filters;
    if (payload && Object.keys(payload).length) {
      dispatch?.({ type: 'ADD_OR_UPDATE_FILTER', payload });
    }
  }, [activeView, dispatch, savedView]);

  // Compare changes for filters and view
  useEffect(() => {
    const selectedView = savedView?.find((view: any) => view.id === activeView);
    if (selectedView) {
      // Compare changes for filters
      if (filterQueryObj !== undefined) {
        setHaveFilterChanges(
          JSON.stringify(filterQueryObj) !== JSON.stringify(selectedView?.filters ?? {}),
        );
      }

      // Compare changes for view options
      if (changedViewOptions) {
        setHaveOptionChanges(
          JSON.stringify(changedViewOptions) !== JSON.stringify(selectedView?.viewOptions),
        );
      }
    }
  }, [activeView, changedViewOptions, filterQueryObj, savedView]);

  return (
    <Deck data-automation-id="MainContainer">
      <Deck.Item hasOverflow>
        <SaveViewContainer
          savedView={savedView}
          tabOnChange={tabOnChange}
          activeView={activeView}
          hasChanges={hasChanges}
        />
      </Deck.Item>
      <Deck.Item hasOverflow>{children}</Deck.Item>
    </Deck>
  );
};
