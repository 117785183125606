import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from 'src/components/mui-components';
import { FormControlLabel } from 'src/components/mui-components/Utils';
import { ISavedView, ISavedViewForm } from 'src/apis/savedViewAPI';
import { Box, Tab, Tabs } from '@mui/material';
import { useState } from 'react';

interface IViewFormDialog {
  onSubmit: (actionType: string, data?: ISavedViewForm) => void;
  form: UseFormReturn<ISavedViewForm, any>;
  showSaveDialog: boolean;
  onClose: () => void;
  selectedSaveViewData: ISavedView;
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: string;
  value: string;
}

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

export const SaveViewDialog = ({
  onSubmit,
  form,
  showSaveDialog,
  onClose,
  selectedSaveViewData,
}: IViewFormDialog) => {
  const { t } = useTranslation('savedView');
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = form;

  const [selectedTab, setSelectedTab] = useState('save');

  const handleChange = (event: React.SyntheticEvent, tab: string) => {
    setSelectedTab(tab);
  };

  return (
    <Dialog
      aria-labelledby="dialog-save-view"
      fullWidth
      maxWidth="xs"
      onClose={() => onClose()}
      open={!!showSaveDialog}
    >
      <DialogTitle id="dialog-save-view">{t('SaveViewModalTitle')}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Tabs value={selectedTab} onChange={handleChange} aria-label="Saved view">
            <Tab value="save" label={t('SaveNewTabText')} wrapped />
            <Tab value="edit" label={t('ReplaceExistingTabText')} wrapped />
          </Tabs>
          <CustomTabPanel value={selectedTab} index="save">
            <Stack gap={2} sx={{ mt: 2 }}>
              <Controller
                name="title"
                control={control}
                render={({ field }) => (
                  <TextField
                    data-automation-id="SavedViewTitleInput"
                    label={t('ViewNameText')}
                    variant="outlined"
                    ariaLabel={t('ViewNameText')}
                    required
                    error={!!errors?.title?.message?.toString()}
                    helperText={errors?.title?.message?.toString()}
                    {...field}
                  />
                )}
              />
              <FormControlLabel
                control={
                  <Controller
                    name="isDefault"
                    control={control}
                    render={({ field: props }) => (
                      <Checkbox
                        {...props}
                        checked={props.value}
                        onChange={(e) => props.onChange(e.target.checked)}
                      />
                    )}
                  />
                }
                label={t('DialogIsDefaultText')}
              />
            </Stack>
          </CustomTabPanel>
          <CustomTabPanel value={selectedTab} index="edit">
            <Stack gap={2} sx={{ mt: 2, mb: 2 }}>
              <TextField
                data-automation-id="SavedViewReplacingInput"
                label={t('ReplacingText')}
                variant="standard"
                ariaLabel={t('ReplacingText')}
                value={selectedSaveViewData?.title}
                disabled
              />
            </Stack>
          </CustomTabPanel>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          {t('DialogCancelText')}
        </Button>
        <Button
          variant="contained"
          disabled={selectedTab === 'save' ? !isDirty || !isValid : false}
          onClick={
            selectedTab === 'save'
              ? handleSubmit((data: ISavedViewForm) => onSubmit(selectedTab, data))
              : () => onSubmit(selectedTab)
          }
          autoFocus
        >
          {t('DialogSaveText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
