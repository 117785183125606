import { Box, Stack } from '@mui/material';
import {
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridColDef,
  GridCsvExportMenuItem,
  GridToolbarExportContainer,
} from '@mui/x-data-grid-pro';
import { GridExcelExportMenuItem } from 'src/components/mui-components/DataGrid/utils';
import { Dispatch, ReactNode } from 'react';
import { useExcelConfig } from './config';

interface IToolbarProps {
  columns: GridColDef[];
  setColumnsButtonEl: Dispatch<React.SetStateAction<HTMLButtonElement | null>>;
  filterSection?: ReactNode;
}

// TO DO for #65672
// const ActionsSelection = ({ selectedAction, setSelectedAction }: ActionsSelectionProps) => {
//   const { t } = useTranslation('searchProjects');
//   const { actions } = useGetProjectActions();

//   return (
//     <Box sx={{ minWidth: 120, ml: 2 }}>
//       <Select
//         defaultValue="0"
//         id="grouped-select"
//         label="Actions"
//         fullWidth
//         size="small"
//         value={selectedAction.value}
//         MenuProps={{
//           PaperProps: {
//             sx: {
//               '.MuiMenuItem-root span': {
//                 marginLeft: 2,
//               },
//             },
//           },
//         }}
//         data-automation-id="ActionsSelectionSelect"
//       >
//         <MenuItem
//           value="0"
//           onClick={() =>
//             setSelectedAction({
//               value: '0',
//             })
//           }
//         >
//           {t('DropdownSelectActionText')}
//         </MenuItem>
//         {actions.map((action) => {
//           if (action?.actions) {
//             // Grouped actions with subheader
//             return [
//               <ListSubheader key={`header-${action.name}`}>
//                 <Typography color="primary">{t(`Dropdown${action.name}Text`)}</Typography>
//               </ListSubheader>,
//               ...action.actions.map(
//                 (subAction: {
//                   name: string;
//                   body?: { [key: string]: string | boolean };
//                   uri?: string;
//                 }) => {
//                   let displayText;
//                   if (subAction.uri === 'status') {
//                     displayText = t(`Status${subAction.name}Text`);
//                   } else if (subAction.uri === 'resource-planner') {
//                     displayText = t(`Dropdown${subAction.name}Text`);
//                   } else {
//                     displayText = subAction.name;
//                   }

//                   return (
//                     <MenuItem
//                       key={subAction.name}
//                       value={`${subAction.name}`}
//                       onClick={() =>
//                         setSelectedAction({
//                           value: `${subAction.name}`,
//                           body: subAction.body,
//                           uri: subAction.uri,
//                         })
//                       }
//                     >
//                       <span>{displayText}</span>
//                     </MenuItem>
//                   );
//                 },
//               ),
//             ];
//           }

//           return (
//             <MenuItem
//               key={action.name}
//               value={action.name}
//               onClick={() =>
//                 setSelectedAction({
//                   value: action.name,
//                 })
//               }
//             >
//               {t(`Dropdown${action.name}Text`)}
//             </MenuItem>
//           );
//         })}
//       </Select>
//     </Box>
//   );
// };

export const Toolbar = ({ columns, setColumnsButtonEl, filterSection }: IToolbarProps) => (
  <GridToolbarContainer>
    <Box display="flex" justifyContent="flex-end" width="100%" sx={{ mb: 2 }}>
      {filterSection}

      <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={0}>
        <GridToolbarColumnsButton
          ref={setColumnsButtonEl}
          slotProps={{
            button: {
              sx: {
                minWidth: 10,
                pl: 2,
              },
            },
          }}
        />
        <GridToolbarExportContainer
          slotProps={{
            button: {
              sx: {
                minWidth: 10,
                pl: 2,
              },
            },
          }}
        >
          <GridCsvExportMenuItem options={{ disableToolbarButton: true }} />
          <GridExcelExportMenuItem columns={columns} config={useExcelConfig()} />
        </GridToolbarExportContainer>
      </Stack>
    </Box>
  </GridToolbarContainer>
);
