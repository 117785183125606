import { ReactNode, useRef } from 'react';
import { ISavedView } from 'src/apis/savedViewAPI';
import { IFilterCategoryProperties } from 'src/apis/types/filterListAPI';
import { SidePanel } from 'src/components/layout/SidePanel';
import { initFilterStore } from 'src/reducers/FilterReducer';
import { IFilterProps, TFilterID } from 'src/reducers/FilterReducer/FilterReducer';
import { TViewOptions } from 'src/screens/ResourcePlanner/types/resourcePlanner';
import { FilterStoreProvider } from 'src/stores/FilterStore';
import { FilterList } from './components/SideBar';
import { MainContainer } from './components/MainContainer';

interface IFilterPanelLayout {
  filterList?: Array<Partial<IFilterCategoryProperties>>;
  selectedFilterList?: Record<TFilterID, IFilterProps>;
  children: ReactNode;
  allowSavedViews?: boolean;
  savedViewsList?: ISavedView[];
  changedViewOptions?: TViewOptions;
  activeView: any;
  setActiveView: any;
}

export const FilterPanelLayout = ({
  filterList = [],
  selectedFilterList = {},
  children,
  allowSavedViews = true,
  savedViewsList = [],
  changedViewOptions,
  activeView,
  setActiveView,
}: IFilterPanelLayout) => {
  const activateSidePanelButtonRef = useRef<HTMLButtonElement>(null);
  return (
    <FilterStoreProvider
      value={{
        ...initFilterStore,
        filterList,
        selectedFilterList,
        filterQueryObj: selectedFilterList,
      }}
    >
      <SidePanel
        sideBar={<FilterList activateSidePanelButtonRef={activateSidePanelButtonRef} />}
        activateSidePanelButtonRef={activateSidePanelButtonRef}
      >
        <MainContainer
          allowSavedViews={allowSavedViews}
          savedView={savedViewsList}
          changedViewOptions={changedViewOptions}
          activeView={activeView}
          setActiveView={setActiveView}
        >
          {children}
        </MainContainer>
      </SidePanel>
    </FilterStoreProvider>
  );
};
