import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import {
  DataGrid,
  dataGridCellBorderLeftThickClassName,
  dataGridCellBorderRightThickClassName,
} from 'src/components/mui-components/DataGrid';
import { useGetLocale } from 'src/components/global/LocaleProvider';
import ResponseHandler from 'src/components/utils/ResponseHandler';
import {
  GRID_CHECKBOX_SELECTION_COL_DEF,
  GRID_CHECKBOX_SELECTION_FIELD,
  GridActionsCellItem,
  GridCellCheckboxRenderer,
  GridColDef,
  GridPinnedRowsProp,
  GridRenderCellParams,
  GridRowModel,
  GridRowParams,
  GridSlots,
  useGridApiRef,
} from '@mui/x-data-grid-pro';
import { useNavigate } from 'react-router-dom';

import { useFilterStore } from 'src/stores/FilterStore';
import { IconButton, ToastifyAlert, Typography } from 'src/components/mui-components';
import { toggleFilterContainerEventName } from 'src/components/layout/FilterLayout/components/MainContainer/components/FilterContainer';
import { useTranslation } from 'react-i18next';
import { useDeleteProjects, useGetProjects, usePutProjects } from 'src/apis/projectsAPI';
import { Box, LinearProgress, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { Delete, EditNote, Edit, WarningAmberOutlined } from '@mui/icons-material';
import { formatTime } from 'src/utils/time';
import { TextLink } from 'src/components/ui-components';
import { toast } from 'react-toastify';
import { useCellSingleClickMode } from 'src/components/mui-components/DataGrid/utils';
import { ViewOptionsChangeParameters } from 'src/components/layout/FilterLayout/types';
import { translationAnyText } from 'src/utils/translation';
import { stringToPascal } from 'src/utils/string';
import { SPSelectedFilterListStateKey } from '../../localStorageKeys';
import { Toolbar } from './Toolbar';

import styles from '../../SearchProjects.module.scss';
import { TimeRegistrationsDialog } from '../TimeRegistrationsDialog';
import { ProjectActionDialog } from '../ProjectActionDialog';
import { ProjectDeleteDialog } from '../ProjectDeleteDialog';
import { ProjectRemoveRPDialog } from '../ProjectRemoveRPDialog';
import { ProjectsErrorMessage } from '../../utils/serverResponseHandler';
import { statusValueOptions } from '../../constants';
import { NoData } from '../NoData';

interface ISelectedAction {
  value: string;
  body?: { [key: string]: string | boolean };
  uri?: string;
}
interface ISearchProject {
  selectedViewOptions: {
    [key: string]: string;
  };
  onViewOptionsChange: (item: ViewOptionsChangeParameters[]) => void;
  filterSection?: any;
}

interface ProjectError {
  projectId: string;
  isDeletedSuccessfully: boolean;
  message: string;
  details: {
    errorCode: number;
    message: string;
  }[];
}

const getColor = (value: number) => {
  if (value === 100) {
    return styles.progressInProgress;
  }
  if (value < 81) {
    return styles.progressPositive;
  }
  if (value < 100) {
    return styles.progressWarning;
  }
  return styles.progressNegative;
};

type TTooltipStatus = 'info' | 'error' | 'success' | 'warning';

interface IStyledTooltip extends TooltipProps {
  status: TTooltipStatus;
}

const hiddenFields = [GRID_CHECKBOX_SELECTION_FIELD, 'hasAccountingIssues', 'action'];

const getTogglableColumns = (columns: GridColDef[]) =>
  columns.filter((column) => !hiddenFields.includes(column.field)).map((column) => column.field);

export const Table = ({
  selectedViewOptions,
  onViewOptionsChange,
  filterSection,
}: ISearchProject) => {
  const { t } = useTranslation('searchProjects');
  const siteLocale = useGetLocale();
  const navigate = useNavigate();

  const apiRef = useGridApiRef();

  const { filterQueryObj } = useFilterStore();
  localStorage.setItem(SPSelectedFilterListStateKey, JSON.stringify(filterQueryObj));

  const {
    isLoading,
    isSuccess,
    isError,
    isEmpty,
    data: rows,
    summations,
  } = useGetProjects({ selectedFilterList: filterQueryObj }, selectedViewOptions);

  const { mutate: updateProjects } = usePutProjects();

  const { mutateAsync: deleteProjects } = useDeleteProjects();

  const [selectedRowProject, setSelectedRowProject] = useState<any>([]);

  const handleRowSelectionChange = () => {
    const selectedRows = apiRef.current.getSelectedRows();
    const formattedRows = Array.from(selectedRows.values()).map((row) => ({
      projectId: row.projectId,
    }));

    setSelectedRowProject(formattedRows);
  };

  // Time Registration Dialog
  const [showTimeRegistration, setShowTimeRegistration] = useState(false);

  // Project delete dialog
  const [showProjectDelete, setShowProjectDelete] = useState(false);

  // Project remove resource planner dialog
  const [showRemoveResourcePlannerDialog, setShowRemoveResourcePlannerDialog] = useState(false);

  const [selectedProjectId, setSelectedProjectId] = useState('');
  const [projectHaveError, setProjectHaveError] = useState<ProjectError[]>([]);

  const [columnsButtonEl, setColumnsButtonEl] = useState<HTMLButtonElement | null>(null);

  const { cellModesModel, handleCellClick, handleCellModesModelChange } = useCellSingleClickMode();

  const memoizedStatusValueOptions = useMemo(
    () =>
      statusValueOptions.map((option) => ({
        ...option,
        label: t(option.label),
      })),
    [t],
  );

  const StyledTooltip = styled(({ className, ...props }: IStyledTooltip) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme, ...props }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette[props.status].main}`,
      boxShadow: theme.shadows[1],
      color: theme.palette[props.status].main,
      fontSize: 11,
      maxWidth: 150,
    },
    [`& .${tooltipClasses.arrow}::before`]: {
      backgroundColor: theme.palette.common.white,
      border: `1px solid ${theme.palette[props.status].main}`,
    },
  }));

  const renderAction = useCallback(
    (params: GridRowParams) => {
      const handleEditProjectClick = (projectId: string) => {
        navigate(`/ProjectManagement/Plan/Index/${projectId}`);
      };

      const handleEditProjectSettingClick = (projectId: string) => {
        navigate(`/ProjectManagement/Setting/Index/${projectId}`);
      };

      if (params.id === 'TOTAL') {
        return [];
      }

      return [
        <GridActionsCellItem
          icon={
            <Tooltip title={t('EditProjectText')}>
              <Edit />
            </Tooltip>
          }
          label={t('EditProjectText')}
          onClick={(event) => {
            event.stopPropagation();
            handleEditProjectClick(params.row.projectId);
          }}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title={t('EditProjectSettingsText')}>
              <EditNote />
            </Tooltip>
          }
          label={t('EditProjectSettingsText')}
          onClick={(event) => {
            event.stopPropagation();
            handleEditProjectSettingClick(params.row.projectId);
          }}
        />,
        <GridActionsCellItem
          icon={
            <Tooltip title={t('DeleteProjectText')}>
              <Delete />
            </Tooltip>
          }
          label={t('DeleteProjectText')}
          onClick={() => {
            setSelectedProjectId(params.row.projectId);
            setSelectedRowProject([]);
            setShowProjectDelete(true);
          }}
        />,
      ];
    },
    [navigate, t],
  );

  const columns: GridColDef[] = useMemo(
    () => [
      {
        ...GRID_CHECKBOX_SELECTION_COL_DEF,
        hideable: false,
        cellClassName: (params) => {
          const hasError = projectHaveError.some(
            (projectError) =>
              projectError.projectId === params.row.projectId &&
              projectError.isDeletedSuccessfully === false,
          );
          return hasError ? 'error-selected-cell' : '';
        },
        renderCell: (params) => {
          const error = projectHaveError.find(
            (projectError) =>
              projectError.projectId === params.row.projectId &&
              projectError.isDeletedSuccessfully === false,
          );

          if (!error) {
            return <GridCellCheckboxRenderer {...params} />;
          }

          return (
            <StyledTooltip
              title={
                <div>
                  {error.details.map(({ errorCode, message }) => (
                    <Box sx={{ mb: 1 }} key={message}>
                      {t(`errors.${ProjectsErrorMessage[errorCode]}`)}
                    </Box>
                  ))}
                </div>
              }
              status="error"
              placement="right"
              followCursor={false}
            >
              <GridCellCheckboxRenderer {...params} />
            </StyledTooltip>
          );
        },
      },
      {
        field: 'hasAccountingIssues',
        headerName: '',
        hideable: false,
        width: 40,
        display: 'flex',
        disableExport: true,
        renderCell: (params: GridRenderCellParams) =>
          params.value ? (
            <IconButton
              data-automation-id="AccountingIssuesIcon"
              title={t('PendingBookingInfo')}
              size="small"
              onClick={() =>
                window.open(`/ProjectManagement/Booking/List/${params.row?.projectId}`, '_blank')
              }
            >
              <WarningAmberOutlined fontSize="small" />
            </IconButton>
          ) : null,
      },
      {
        field: 'no',
        headerName: t('TableHeaderProjectNo'),
        minWidth: 100,
        flex: 0.1,
        renderCell: (params: GridRenderCellParams) => (
          <TextLink
            key={0}
            className={styles.link}
            useTextColor
            href={`/ProjectManagement/Plan/Index/${params.row.projectId}`}
            title={params.value}
          >
            {params.value}
          </TextLink>
        ),
        valueGetter: (value, row) => {
          if (row.id === 'TOTAL') {
            return row.name;
          }
          return value;
        },
        colSpan: (value, row) => {
          if (row.id === 'TOTAL') {
            return 2;
          }
          return undefined;
        },
      },
      {
        field: 'name',
        headerName: t('TableHeaderProjectName'),
        minWidth: 250,
        flex: 1,
        renderCell: (params: GridRenderCellParams) => (
          <TextLink
            key={0}
            className={styles.link}
            useTextColor
            href={`/ProjectManagement/Plan/Index/${params.row.projectId}`}
            title={params.value}
          >
            {params.value}
          </TextLink>
        ),
      },
      {
        field: 'completion',
        headerName: t('TableHeaderCompletionMeter'),
        minWidth: 120,
        renderCell: (params: GridRenderCellParams) => {
          if (params.id === 'TOTAL') {
            return '';
          }
          const value = parseInt(params.value as string, 10);
          return (
            value >= 0 && (
              <Box
                sx={{
                  width: '100%',
                  height: '100%',
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <LinearProgress
                  className={getColor(value)}
                  variant="determinate"
                  value={Math.min(value, 100)}
                  sx={{
                    position: 'absolute',
                    width: '100%',
                    height: '58%',
                    borderRadius: 3,
                    overflow: 'hidden',
                  }}
                />
                <Typography sx={{ position: 'absolute', color: 'white' }}>{`${value}%`}</Typography>
              </Box>
            )
          );
        },
      },
      {
        field: 'customerName',
        headerName: t('TableHeaderCustomer'),
        minWidth: 120,
        flex: 0.1,
        renderCell: (params: GridRenderCellParams) => (
          <TextLink
            key={0}
            className={styles.link}
            useTextColor
            href={`/CRM/Customer/Edit/${params.row.customerId}`}
            title={params.value}
          >
            {params.value}
          </TextLink>
        ),
      },
      {
        field: 'projectManagerInitials',
        headerName: t('TableHeaderProjectManager'),
        minWidth: 120,
        flex: 0.1,
        renderCell: (params) => <span title={params.row.projectManagerName}>{params.value}</span>,
      },
      {
        field: 'accountManagerInitials',
        headerName: t('TableHeaderAccountManager'),
        minWidth: 120,
        flex: 0.1,
        renderCell: (params) => <span title={params.row.accountManagerName}>{params.value}</span>,
      },
      {
        field: 'projectTypeName',
        headerName: t('TableHeaderProjectType'),
        minWidth: 130,
        flex: 0.1,
      },
      {
        field: 'projectCategoryName',
        headerName: t('TableHeaderProjectCategory'),
        minWidth: 130,
        flex: 0.1,
      },
      {
        field: 'projectStageName',
        headerName: t('TableHeaderProjectStage'),
        minWidth: 130,
        flex: 0.1,
      },
      {
        field: 'budgetWorkHours',
        headerName: t('TableHeaderProjectBudgetHours'),
        minWidth: 120,
        flex: 0.1,

        headerAlign: 'right',
        align: 'right',
        headerClassName: `${dataGridCellBorderLeftThickClassName}`,
        cellClassName: `${dataGridCellBorderLeftThickClassName}`,
        renderCell: (params) => formatTime(params.value, siteLocale),
      },
      {
        field: 'tasksBudgetHours',
        headerName: t('TableHeaderTaskBudgetHours'),
        minWidth: 120,
        flex: 0.1,
        headerAlign: 'right',
        align: 'right',
        renderCell: (params) => formatTime(params.value, siteLocale),
      },
      {
        field: 'allocationsBudgetHours',
        headerName: t('TableHeaderAllocatedBudgetHours'),
        minWidth: 120,
        flex: 0.1,
        headerAlign: 'right',
        align: 'right',
        headerClassName: `${dataGridCellBorderRightThickClassName}`,
        cellClassName: `${dataGridCellBorderRightThickClassName}`,
        renderCell: (params) => formatTime(params.value, siteLocale),
      },
      {
        field: 'projectActualHours',
        headerName: t('TableHeaderActualHours'),
        minWidth: 120,
        flex: 0.1,
        headerAlign: 'right',
        align: 'right',
        display: 'flex',
        renderCell: (params: GridRenderCellParams) => (
          <Box position="relative">
            {params.id !== 'TOTAL' && params.value !== 0 && (
              <IconButton
                data-automation-id="TimeRegistrationIcon"
                className={styles.cornerInfo}
                title={t('ViewTimeRegistrationText')}
                onClick={() => {
                  setSelectedProjectId(params.row.projectId);
                  setShowTimeRegistration(true);
                }}
              />
            )}
            {formatTime(params.value, siteLocale)}
          </Box>
        ),
      },
      {
        field: 'action',
        headerName: '',
        type: 'actions',
        minWidth: 130,
        flex: 0.1,
        getActions: renderAction,
      },
      {
        field: 'statusId',
        headerName: t('TableHeaderStatus'),
        type: 'singleSelect',
        valueOptions: memoizedStatusValueOptions,
        minWidth: 130,
        flex: 0.1,
        editable: true,
        display: 'flex',
        renderCell: (params: GridRenderCellParams) => {
          const statusOption = memoizedStatusValueOptions.find(
            (option) => option.value === params.row.statusId,
          );
          const Icon = statusOption ? statusOption.icon : null;
          return (
            <Box display="flex" alignItems="center">
              {Icon ? <Icon fontSize="small" /> : null}
              <Typography sx={{ ml: 1, fontSize: 12 }}>{statusOption?.label}</Typography>
            </Box>
          );
        },
      },
    ],
    [StyledTooltip, memoizedStatusValueOptions, projectHaveError, renderAction, siteLocale, t],
  );

  const pinnedRows: GridPinnedRowsProp = {
    bottom: [...[summations]],
  };

  const [selectedAction, setSelectedAction] = useState<ISelectedAction>({ value: '0', body: {} });

  // Project status or stage update Dialog
  const [showActionUpdate, setShowActionUpdate] = useState(false);

  const applyProjectsUpdate = async (uri?: string, validDate?: Date) => {
    if (uri === 'delete') {
      await deleteProjects(selectedRowProject, {
        onSuccess: () => {
          toast.success(<ToastifyAlert description={t('ToastProjectsDeletedText')} />, {
            autoClose: 5000,
            closeButton: false,
          });
        },
        onError: (error) => {
          setProjectHaveError(error as any);
        },
      });
    } else if (uri === 'resource-planner') {
      await updateProjects(
        {
          updateModel: {
            isAddingToResourcePlanner: selectedAction?.body?.isAddingToResourcePlanner ?? undefined,
            date: validDate,
          },
          fieldName: uri,
          projectsId: selectedRowProject,
        },
        {
          onSuccess: (responseData) => {
            if (responseData?.properties?.updatedProjectsCount !== '0')
              toast.success(
                <ToastifyAlert
                  description={`${responseData?.properties?.updatedProjectsCount} ${t(
                    selectedAction?.body?.isAddingToResourcePlanner
                      ? 'ToastActionMigrateToResourcePlannerText'
                      : 'ToastActionRemoveFromResourcePlannerText',
                  )}`}
                />,
                {
                  autoClose: 5000,
                  closeButton: false,
                },
              );
          },
          onError: () => {
            toast.error(<ToastifyAlert description={t('UnknownErrorMessage')} />, {
              autoClose: 5000,
              closeButton: false,
            });
          },
        },
      );
    } else if (uri === 'status') {
      await updateProjects(
        {
          updateModel: {
            status: selectedAction?.body?.status,
            date: validDate,
          },
          fieldName: uri,
          projectsId: selectedRowProject,
        },
        {
          onSuccess: (responseData) => {
            toast.success(
              <ToastifyAlert
                description={`${responseData?.properties?.updatedProjectsCount} ${t(
                  'ToastProjectsStatusUpdatedText',
                )} ${translationAnyText(t, `Status${stringToPascal(selectedAction?.value)}Text`)}`}
              />,
              {
                autoClose: 5000,
                closeButton: false,
              },
            );
          },
          onError: () => {
            toast.error(<ToastifyAlert description={t('UnknownErrorMessage')} />, {
              autoClose: 5000,
              closeButton: false,
            });
          },
        },
      );
    } else if (uri === 'stage') {
      await updateProjects(
        {
          updateModel: {
            stage: selectedAction?.body?.stage,
            date: validDate,
          },
          fieldName: uri,
          projectsId: selectedRowProject,
        },
        {
          onSuccess: (responseData) => {
            toast.success(
              <ToastifyAlert
                description={`${responseData?.properties?.updatedProjectsCount} ${t(
                  'ToastProjectsStageUpdatedText',
                )} ${selectedAction?.value}`}
              />,
              {
                autoClose: 5000,
                closeButton: false,
              },
            );
          },
          onError: () => {
            toast.error(<ToastifyAlert description={t('UnknownErrorMessage')} />, {
              autoClose: 5000,
              closeButton: false,
            });
          },
        },
      );
    }
  };

  const handleApplyClick = () => {
    if (selectedAction.value === 'DeleteAction') {
      setSelectedProjectId('');
      setShowProjectDelete(true);
    } else if (selectedAction.value === 'DisableResourcePlanner') {
      setShowRemoveResourcePlannerDialog(true);
    } else if (selectedAction.value === 'EnableResourcePlanner') {
      applyProjectsUpdate('resource-planner');
    } else {
      setShowActionUpdate(true);
    }
  };

  // Handle single project status update on datagrid
  const processRowUpdate = useCallback(
    async (newRow: GridRowModel, oldRow: GridRowModel) => {
      if (newRow.statusId === oldRow.statusId) {
        return newRow;
      }
      await updateProjects(
        {
          updateModel: {
            status: newRow.statusId,
          },
          fieldName: 'status',
          projectsId: [
            {
              projectId: newRow.projectId,
            },
          ],
        },
        {
          onSuccess: () => {
            const statusLabel =
              statusValueOptions.find((option) => option.value === newRow.statusId)?.label ||
              'DefaultStatusText';

            toast.success(
              <ToastifyAlert
                description={`${t('ToastProjectStatusUpdatedText')} ${t(statusLabel)} `}
              />,
              {
                autoClose: 5000,
                closeButton: false,
              },
            );
          },
          onError: () => {
            toast.error(<ToastifyAlert description={t('UnknownErrorMessage')} />, {
              autoClose: 5000,
              closeButton: false,
            });
          },
        },
      );

      return newRow;
    },
    [t, updateProjects],
  );

  // Height of the table
  const dataGridContainerRef = useRef<HTMLDivElement>(null);
  const [offSetTop, setOffSetTop] = useState<number>(0);

  // Set on initial load
  useEffect(() => {
    if (dataGridContainerRef.current) {
      setOffSetTop(dataGridContainerRef.current.offsetTop);
    }
  }, [isSuccess]);

  const listener = (ref: HTMLDivElement | null) => {
    if (ref) {
      setTimeout(() => {
        setOffSetTop(ref.offsetTop);
      }, 1000);
    }
  };

  // Set on FilterContainer toggle
  useEffect(() => {
    const ref = dataGridContainerRef.current;
    window.addEventListener(toggleFilterContainerEventName, () => listener(ref));

    return () => {
      window.removeEventListener(toggleFilterContainerEventName, () => listener(ref));
    };
  }, [dataGridContainerRef]);

  if (isEmpty) {
    return <NoData onViewOptionsChange={onViewOptionsChange} />;
  }

  return (
    <ResponseHandler isLoading={isLoading} isEmpty={isEmpty} isError={isError}>
      <div
        style={{ width: '100%', height: `calc(100vh - ${offSetTop + 20}px)` }}
        ref={dataGridContainerRef}
      >
        <DataGrid
          apiRef={apiRef}
          columns={columns}
          rows={rows}
          disableColumnMenu
          hideFooter
          checkboxSelection
          disableRowSelectionOnClick
          pinnedRows={pinnedRows}
          onRowSelectionModelChange={() => handleRowSelectionChange()}
          rowHeight={34}
          initialState={{
            columns: {
              columnVisibilityModel: {
                accountManagerInitials: false,
                tasksBudgetHours: false,
                allocationsBudgetHours: false,
              },
            },
          }}
          processRowUpdate={processRowUpdate}
          cellModesModel={cellModesModel}
          onCellModesModelChange={handleCellModesModelChange}
          onCellClick={handleCellClick}
          slots={{
            toolbar: Toolbar as GridSlots['toolbar'],
          }}
          // To hide the toolbar text
          localeText={{
            toolbarColumns: '',
            toolbarFilters: '',
            toolbarDensity: '',
            toolbarExport: '',
          }}
          slotProps={{
            panel: {
              anchorEl: columnsButtonEl,
              placement: 'bottom-end',
            },
            toolbar: {
              selectedRowProject,
              columns,
              handleApplyClick,
              selectedAction,
              setSelectedAction,
              setColumnsButtonEl,
              filterSection,
            },
            columnsManagement: {
              getTogglableColumns,
            },
          }}
          sx={{
            fontSize: 12,
            // Allow long text to wrap in the header
            '& .MuiDataGrid-columnHeaderTitle': {
              textOverflow: 'clip',
              whiteSpace: 'break-spaces',
              lineHeight: 'normal',
            },
            '& .error-selected-cell': {
              backgroundColor: '#c92726',
              svg: {
                fill: 'white',
              },
            },
            '& .MuiDataGrid-columnHeader--filledGroup.MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnHeader--filledGroup .MuiDataGrid-columnSeparator': {
              display: 'none',
              cursor: 'default',
            },
          }}
          data-automation-id="SearchProjectsTable"
        />
        {/* Time registration Dialog */}
        {showTimeRegistration && selectedProjectId && (
          <TimeRegistrationsDialog
            showTimeRegistration={showTimeRegistration}
            setShowTimeRegistration={setShowTimeRegistration}
            projectId={selectedProjectId}
          />
        )}
        {/* Project delete Dialog */}
        <ProjectDeleteDialog
          showProjectDelete={showProjectDelete}
          setShowProjectDelete={setShowProjectDelete}
          projectId={selectedProjectId}
          selectedProjectsId={selectedRowProject}
          applyProjectsUpdate={applyProjectsUpdate}
        />

        {/* Project remove from resource planner Dialog */}
        <ProjectRemoveRPDialog
          showRemoveResourcePlannerDialog={showRemoveResourcePlannerDialog}
          setShowRemoveResourcePlannerDialog={setShowRemoveResourcePlannerDialog}
          applyProjectsUpdate={applyProjectsUpdate}
        />

        {/* Project status or stage Dialog when click Apply */}
        <ProjectActionDialog
          showActionUpdate={showActionUpdate}
          setShowActionUpdate={setShowActionUpdate}
          selectedAction={selectedAction}
          applyProjectsUpdate={applyProjectsUpdate}
        />
      </div>
    </ResponseHandler>
  );
};
