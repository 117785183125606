import React, { useState, useEffect, ReactNode, useRef } from 'react';
import { Stack, TextField, InputAdornment, IconButton, Badge } from '@mui/material';
import { Search, Clear, FilterList } from '@mui/icons-material';
import { useDebounce } from 'use-debounce';
import { Button } from 'src/components/mui-components';
import { useSelectedFilterListInArray } from 'src/stores/FilterStore';
import { useSidePanelDispatch } from 'src/components/layout/SidePanel';
import { useTranslation } from 'react-i18next';
import {
  IViewOptionsField,
  ViewOptionsChangeParameters,
} from 'src/components/layout/FilterPanelLayout/types';
import { ViewOptions } from '../ViewOptions';

interface FilterSectionProps {
  changedViewOptions?: any;
  onViewOptionsChange: (options: { name: string; value: string }[]) => void;
  viewOptionsLeftFilter?: ReactNode;
  viewOptionsFields?: Array<IViewOptionsField>;
  viewOptionsChange?: (item: ViewOptionsChangeParameters[]) => void;
}

export const FilterSection: React.FC<FilterSectionProps> = ({
  changedViewOptions,
  onViewOptionsChange,
  viewOptionsLeftFilter,
  viewOptionsFields,
  viewOptionsChange,
}) => {
  const { t } = useTranslation('searchProjects');
  const { t: filter } = useTranslation('filter');

  // Filter sidebar

  const selectedFilterList = useSelectedFilterListInArray();
  const sidePanelDispatch = useSidePanelDispatch();

  const activateSidePanelButtonRef = useRef<HTMLButtonElement>(null);

  const toggleFilterPanel = () => {
    sidePanelDispatch?.({ type: 'TOGGLE_PANEL' });
  };

  // Text search
  const [searchValue, setSearchValue] = useState(changedViewOptions?.searchText);
  const [debouncedSearchValue] = useDebounce(searchValue, 250);

  useEffect(() => {
    setSearchValue(changedViewOptions?.searchText);
  }, [changedViewOptions?.searchText]);

  useEffect(() => {
    if (debouncedSearchValue !== changedViewOptions?.searchText) {
      onViewOptionsChange?.([{ name: 'searchText', value: debouncedSearchValue }]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchValue]);

  const handleSearchChange = (event: any) => {
    setSearchValue(event.target.value);
  };

  const handleSearchClear = () => {
    setSearchValue('');
    onViewOptionsChange?.([{ name: 'searchText', value: '' }]);
  };

  return (
    <Stack direction="row" alignItems="center">
      <TextField
        variant="outlined"
        size="small"
        value={searchValue}
        onChange={handleSearchChange}
        title={t('FilterFullTextSearchText')}
        placeholder={filter('FilterSearchPlaceholderText')}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="clear text"
                onClick={handleSearchClear}
                edge="end"
                size="small"
              >
                <Clear fontSize="small" />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <ViewOptions
        viewOptionsLeftFilter={viewOptionsLeftFilter}
        viewOptionsFields={viewOptionsFields}
        viewOptionsChange={viewOptionsChange}
      />
      <Button
        variant="text"
        startIcon={
          <Badge
            badgeContent={selectedFilterList.length}
            color="primary"
            data-automation-id="FilterContainerAddFilterCount"
          >
            <FilterList />
          </Badge>
        }
        onClick={toggleFilterPanel}
        ref={activateSidePanelButtonRef}
        data-automation-id="FilterContainerAddFilter"
      >
        {filter('ShowActiveFiltersButton')}
      </Button>
    </Stack>
  );
};
