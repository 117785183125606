import { Controller, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Stack,
  TextField,
} from 'src/components/mui-components';
import { FormControlLabel } from 'src/components/mui-components/Utils';
import { ISavedView, ISavedViewForm } from 'src/apis/savedViewAPI';
import { Box } from '@mui/material';

interface IViewFormDialog {
  onSubmit: (actionType: string, data?: ISavedViewForm) => void;
  form: UseFormReturn<ISavedViewForm, any>;
  showEditDialog: boolean;
  onClose: () => void;
  selectedSaveViewData: ISavedView;
}

export const EditViewDialog = ({
  onSubmit,
  form,
  showEditDialog,
  onClose,
  selectedSaveViewData,
}: IViewFormDialog) => {
  const { t } = useTranslation('savedView');
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty, isValid },
  } = form;

  const handleDelete = () => {
    onSubmit('delete', selectedSaveViewData);
  };

  return (
    <Dialog
      aria-labelledby="dialog-edit-view"
      fullWidth
      maxWidth="xs"
      onClose={() => onClose()}
      open={!!showEditDialog}
    >
      <DialogTitle id="dialog-edit-view">{t('UpdateViewModalTitle')}</DialogTitle>
      <DialogContent>
        <Box sx={{ width: '100%' }}>
          <Stack gap={2} sx={{ mt: 2 }}>
            <Controller
              name="title"
              control={control}
              render={({ field }) => (
                <TextField
                  data-automation-id="EditViewTitleInput"
                  label={t('ViewNameText')}
                  variant="outlined"
                  ariaLabel={t('ViewNameText')}
                  required
                  error={!!errors.title?.message?.toString()}
                  helperText={errors.title?.message?.toString()}
                  {...field}
                />
              )}
            />
            <FormControlLabel
              control={
                <Controller
                  name="isDefault"
                  control={control}
                  render={({ field: props }) => (
                    <Checkbox
                      {...props}
                      checked={props.value}
                      onChange={(e) => props.onChange(e.target.checked)}
                    />
                  )}
                />
              }
              label={t('DialogIsDefaultText')}
            />
            <Box alignSelf="flex-start">
              <Button variant="text" onClick={() => handleDelete()}>
                {t('DeleteViewText')}
              </Button>
            </Box>
          </Stack>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={() => onClose()}>
          {t('DialogCancelText')}
        </Button>
        <Button
          variant="contained"
          disabled={!isDirty || !isValid}
          onClick={handleSubmit((data) => onSubmit('edit', data))}
          autoFocus
        >
          {t('DialogSaveText')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
