import { isDev } from 'src/utils/env';
import { datadogLogs } from '@datadog/browser-logs';

if (import.meta.env.VITE_DATADOG_CLIENT_TOKEN && !isDev) {
  datadogLogs.init({
    clientToken: import.meta.env.VITE_DATADOG_CLIENT_TOKEN,
    site: 'datadoghq.eu',
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    service: 'reactwebapp',
    env: import.meta.env.VITE_DATADOG_ENV,
    version: import.meta.env.VITE_VERSION,
    beforeSend: (log: any) => {
      // prevent any custom console log send to datadog
      if (log?.error?.origin === 'console') {
        return false;
      }

      return true;
    },
  });
}
